import React, { useRef, useState, useContext } from 'react';
import { ScreenContext } from '../Providers';
import Routes from '../../routes';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import PageTransitionLink from '../PageTransitionLink';
import ScrollSection from '../ScrollSection';
import Logomark from '../_svgs/Logomark';
import { TextBodySmall, TextBodyLarge } from '../TextStyles';
import {
  ErrorMessage,
  FooterWrapper,
  FooterMain,
  FooterStart,
  FooterMark,
  FooterMenu,
  FooterMenuItem,
  FooterContact,
  FooterExternalLink,
  FooterNewsletter,
  FooterCopyright,
  FooterSignUp,
  FooterSignUpInput,
  FooterSignUpInputLabel,
  FooterSignUpForm,
  FooterSignUpText,
  FooterToTop,
  FooterSignupThankyou,
} from './index.style';
import AnimateFadeIn from '../_animation/AnimateFadeIn';
import Button from '../Button';
import { buttonVariants } from '../Button/index.style';
import IconArrowHeadRight from '../_svgs/IconArrowheadRight';

import { graphql, useStaticQuery } from 'gatsby';
import addToMailchimp from 'utils/mailchimp';

import { HUME_JOBS_URL } from '../../../site.config';
import { scrollToSection } from 'utils/utils';

const Footer = ({ backgroundColor }) => {
  const [newsletterSubmitted, setNewsletterSubmitted] = useState(false);
  const [newsletterError, setNewsletterError] = useState();
  const desktopFormInput = useRef();
  const mobileFormInput = useRef();
  const screen = useContext(ScreenContext);

  const {
    contentfulNewsletter,
    site: {
      siteMetadata: { signUpURL },
    },
  } = useStaticQuery(graphql`
    query {
      contentfulNewsletter {
        text
      }
      site {
        siteMetadata {
          signUpURL
        }
      }
    }
  `);

  const submitForm = (e) => {
    e.preventDefault();
    setNewsletterError('');

    const endpoint =
      'https://hume.us13.list-manage.com/subscribe/post?u=49613932ce00630af8e52d83f&amp;id=71a24dc079&amp;f_id=0044e2e2f0';
    const email =
      desktopFormInput.current.value || mobileFormInput.current.value;
    const tags = '6667843';

    addToMailchimp(endpoint, email, tags)
      .then((res) => {
        if (res.result === 'error') {
          setNewsletterError(res.msg);
          return;
        }
        if (res.result === 'success') {
          setNewsletterSubmitted(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <ScrollSection>
      <FooterWrapper backgroundColor={backgroundColor}>
        <Container>
          <Grid>
            <GridItem>
              <FooterSignUp>
                <Grid>
                  <GridItem desk={4} deskStart={2}>
                    <FooterSignUpText>
                      <AnimateFadeIn>
                        <TextBodyLarge>
                          {contentfulNewsletter.text}
                        </TextBodyLarge>
                      </AnimateFadeIn>
                    </FooterSignUpText>
                  </GridItem>
                  <GridItem desk={5} deskStart={7}>
                    <AnimateFadeIn>
                      <FooterSignUpForm onSubmit={submitForm}>
                        <FooterSignUpInputLabel htmlFor={`k_id_email`}>
                          Enter email for updates
                        </FooterSignUpInputLabel>
                        <FooterSignUpInput
                          type="email"
                          name="email"
                          id="k_id_email"
                          placeholder={`Enter email for updates`}
                          ref={desktopFormInput}
                          onChange={(e) => {
                            if (e.target.value && newsletterError)
                              setNewsletterError('');
                          }}
                        />
                        <Button
                          variant={buttonVariants.plain}
                          iconRight={<IconArrowHeadRight />}
                          ariaLabel={`Submit`}
                          type={`submit`}
                        ></Button>
                      </FooterSignUpForm>
                    </AnimateFadeIn>
                    {newsletterError && (
                      <ErrorMessage>{newsletterError}</ErrorMessage>
                    )}
                  </GridItem>
                </Grid>

                <FooterSignupThankyou show={newsletterSubmitted}>
                  <TextBodyLarge>
                    Thanks for signing up, please check your email for a
                    confirmation message.
                  </TextBodyLarge>
                </FooterSignupThankyou>
              </FooterSignUp>
            </GridItem>
          </Grid>

          <Grid>
            <GridItem tiny={3} mobile={2} mobileL={2} tabletP={1}>
              <FooterStart>
                <AnimateFadeIn>
                  <FooterMark>
                    <Logomark responsive />
                  </FooterMark>
                </AnimateFadeIn>
              </FooterStart>
            </GridItem>

            <GridItem tiny={13} tabletP={15}>
              <FooterMain>
                <Grid tabletP={15}>
                  <GridItem
                    tinyStart={3}
                    tiny={8}
                    mobileStart={3}
                    mobile={7}
                    mobileLStart={3}
                    mobileL={4}
                    tabletP={3}
                    tabletPStart={2}
                    tabletPOrder={1}
                    desk={2}
                    deskStart={2}
                    deskOrder={1}
                  >
                    <FooterContact>
                      <FooterMenu>
                        <FooterMenuItem>
                          <AnimateFadeIn delay={0.5}>
                            <PageTransitionLink to={Routes.home}>
                              <Button
                                variant={buttonVariants.plain}
                                lower={true}
                              >
                                Home
                              </Button>
                            </PageTransitionLink>
                          </AnimateFadeIn>
                        </FooterMenuItem>

                        <FooterMenuItem>
                          <AnimateFadeIn delay={0.4}>
                            <PageTransitionLink to={Routes.about}>
                              <Button
                                variant={buttonVariants.plain}
                                lower={true}
                              >
                                About
                              </Button>
                            </PageTransitionLink>
                          </AnimateFadeIn>
                        </FooterMenuItem>

                        <FooterMenuItem>
                          <AnimateFadeIn delay={0.6}>
                            <PageTransitionLink to={Routes.science}>
                              <Button
                                variant={buttonVariants.plain}
                                lower={true}
                              >
                                Science
                              </Button>
                            </PageTransitionLink>
                          </AnimateFadeIn>
                        </FooterMenuItem>

                        <FooterMenuItem>
                          <AnimateFadeIn delay={0.6}>
                            <PageTransitionLink to={Routes.solutions}>
                              <Button
                                variant={buttonVariants.plain}
                                lower={true}
                              >
                                Products
                              </Button>
                            </PageTransitionLink>
                          </AnimateFadeIn>
                        </FooterMenuItem>

                        {(screen.tabletP ||
                          screen.tabletL ||
                          screen.mobileL ||
                          screen.mobile ||
                          screen.tiny) && (
                          <>
                            <FooterMenuItem>
                              <AnimateFadeIn delay={0.3}>
                                <PageTransitionLink to={Routes.hub}>
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    Blog
                                  </Button>
                                </PageTransitionLink>
                              </AnimateFadeIn>
                            </FooterMenuItem>

                            <FooterMenuItem>
                              <AnimateFadeIn delay={0.7}>
                                <PageTransitionLink to={Routes.contact}>
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    Contact
                                  </Button>
                                </PageTransitionLink>
                              </AnimateFadeIn>
                            </FooterMenuItem>

                            <FooterMenuItem>
                              <AnimateFadeIn delay={0.8}>
                                <TextBodySmall>
                                  <FooterExternalLink
                                    href={HUME_JOBS_URL}
                                    target={`_blank`}
                                  >
                                    <Button
                                      variant={buttonVariants.plain}
                                      lower={true}
                                    >
                                      Careers
                                    </Button>
                                  </FooterExternalLink>
                                </TextBodySmall>
                              </AnimateFadeIn>
                            </FooterMenuItem>

                            <FooterMenuItem>
                              <AnimateFadeIn delay={0.9}>
                                <PageTransitionLink to={Routes.privacyPolicy}>
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    Privacy Policy
                                  </Button>
                                </PageTransitionLink>
                              </AnimateFadeIn>
                            </FooterMenuItem>
                          </>
                        )}

                        {(screen.mobile || screen.mobileL || screen.tiny) && (
                          <>
                            <FooterMenuItem>
                              <AnimateFadeIn delay={1}>
                                <TextBodySmall>
                                  <FooterExternalLink
                                    href={`https://beta.hume.ai/pricing`}
                                  >
                                    <Button
                                      variant={buttonVariants.plain}
                                      lower={true}
                                    >
                                      Pricing
                                    </Button>
                                  </FooterExternalLink>
                                </TextBodySmall>
                              </AnimateFadeIn>
                            </FooterMenuItem>

                            <FooterMenuItem>
                              <AnimateFadeIn delay={1.1}>
                                <TextBodySmall>
                                  <FooterExternalLink
                                    href={`https://twitter.com/hume_ai`}
                                    target={`_blank`}
                                  >
                                    <Button
                                      variant={buttonVariants.plain}
                                      lower={true}
                                    >
                                      @hume_ai
                                    </Button>
                                  </FooterExternalLink>
                                </TextBodySmall>
                              </AnimateFadeIn>
                            </FooterMenuItem>
                          </>
                        )}
                      </FooterMenu>
                    </FooterContact>
                  </GridItem>

                  {!(
                    screen.tabletP ||
                    screen.tabletL ||
                    screen.mobile ||
                    screen.mobileL ||
                    screen.tiny
                  ) && (
                    <GridItem
                      tabletP={4}
                      tabletPStart={2}
                      tabletPOrder={4}
                      desk={2}
                      deskStart={4}
                      deskOrder={2}
                    >
                      <FooterContact>
                        <FooterMenu>
                          <FooterMenuItem>
                            <AnimateFadeIn delay={0.6}>
                              <PageTransitionLink to={Routes.hub}>
                                <Button
                                  variant={buttonVariants.plain}
                                  lower={true}
                                >
                                  Blog
                                </Button>
                              </PageTransitionLink>
                            </AnimateFadeIn>
                          </FooterMenuItem>

                          <FooterMenuItem>
                            <AnimateFadeIn delay={0.7}>
                              <PageTransitionLink to={Routes.contact}>
                                <Button
                                  variant={buttonVariants.plain}
                                  lower={true}
                                >
                                  Contact
                                </Button>
                              </PageTransitionLink>
                            </AnimateFadeIn>
                          </FooterMenuItem>

                          <FooterMenuItem>
                            <AnimateFadeIn delay={0.8}>
                              <TextBodySmall>
                                <FooterExternalLink
                                  href={`https://jobs.wrk.xyz/hume-ai`}
                                  target={`_blank`}
                                >
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    Careers
                                  </Button>
                                </FooterExternalLink>
                              </TextBodySmall>
                            </AnimateFadeIn>
                          </FooterMenuItem>

                          <FooterMenuItem>
                            <AnimateFadeIn delay={0.9}>
                              <PageTransitionLink to={Routes.privacyPolicy}>
                                <Button
                                  variant={buttonVariants.plain}
                                  lower={true}
                                >
                                  Privacy Policy
                                </Button>
                              </PageTransitionLink>
                            </AnimateFadeIn>
                          </FooterMenuItem>
                        </FooterMenu>
                      </FooterContact>
                    </GridItem>
                  )}

                  {!(screen.mobile || screen.mobileL || screen.tiny) && (
                    <GridItem
                      tabletP={4}
                      tabletPStart={5}
                      tabletLStart={6}
                      tabletPOrder={2}
                      desk={2}
                      deskStart={6}
                      deskOrder={3}
                    >
                      <FooterContact>
                        <FooterMenu>
                          <FooterMenuItem>
                            <AnimateFadeIn delay={1}>
                              <TextBodySmall>
                                <FooterExternalLink
                                  href={`https://beta.hume.ai/pricing`}
                                >
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    Pricing
                                  </Button>
                                </FooterExternalLink>
                              </TextBodySmall>
                            </AnimateFadeIn>
                          </FooterMenuItem>

                          <FooterMenuItem>
                            <AnimateFadeIn delay={1.1}>
                              <TextBodySmall>
                                <FooterExternalLink
                                  href={`https://twitter.com/hume_ai`}
                                  target={`_blank`}
                                >
                                  <Button
                                    variant={buttonVariants.plain}
                                    lower={true}
                                  >
                                    @hume_ai
                                  </Button>
                                </FooterExternalLink>
                              </TextBodySmall>
                            </AnimateFadeIn>
                          </FooterMenuItem>

                          {(screen.tabletP || screen.tabletL) && (
                            <FooterMenuItem>
                              <AnimateFadeIn delay={1}>
                                <PageTransitionLink to={Routes.solutions}>
                                  <Button
                                    variant={buttonVariants.outline}
                                    lower={true}
                                    onClick={() => {
                                      window.gtag('event', 'conversion', {
                                        send_to:
                                          'AW-10927571180/jwKuCO2k6MMDEOyB1too',
                                      });
                                    }}
                                  >
                                    Sign up
                                  </Button>
                                </PageTransitionLink>
                              </AnimateFadeIn>
                            </FooterMenuItem>
                          )}
                        </FooterMenu>
                      </FooterContact>
                    </GridItem>
                  )}

                  {!(
                    screen.tabletP ||
                    screen.tabletL ||
                    screen.mobile ||
                    screen.mobileL ||
                    screen.tiny
                  ) && (
                    <GridItem
                      tabletPOrder={5}
                      tabletPStart={6}
                      tabletP={5}
                      deskStart={8}
                      desk={3}
                      deskOrder={4}
                    >
                      <FooterContact>
                        <FooterMenu>
                          <FooterMenuItem>
                            <AnimateFadeIn delay={1}>
                              <PageTransitionLink to={signUpURL}>
                                <Button
                                  variant={buttonVariants.outline}
                                  lower={true}
                                >
                                  Sign up
                                </Button>
                              </PageTransitionLink>
                            </AnimateFadeIn>
                          </FooterMenuItem>
                        </FooterMenu>
                      </FooterContact>
                    </GridItem>
                  )}

                  <GridItem
                    tinyStart={3}
                    tiny={13}
                    mobileStart={3}
                    mobileLStart={3}
                    tabletPStart={10}
                    tabletLStart={10}
                    tabletPOrder={3}
                    tabletP={5}
                    tabletL={4}
                    desk={1}
                    deskOrder={5}
                  >
                    <FooterNewsletter>
                      <AnimateFadeIn delay={1}>
                        <TextBodySmall>
                          {contentfulNewsletter.text}
                        </TextBodySmall>

                        <FooterSignUpForm
                          onSubmit={submitForm}
                          show={!newsletterSubmitted}
                        >
                          <FooterSignUpInputLabel htmlFor={`k_id_email`}>
                            Enter email
                          </FooterSignUpInputLabel>
                          <FooterSignUpInput
                            type={`email`}
                            name={`email`}
                            id={`k_id_email`}
                            placeholder={`Enter email`}
                            ref={mobileFormInput}
                            onChange={(e) => {
                              if (e.target.value && newsletterError)
                                setNewsletterError('');
                            }}
                          />
                          <Button
                            variant={buttonVariants.plain}
                            iconRight={<IconArrowHeadRight />}
                            ariaLabel={`Submit`}
                            type={`submit`}
                          ></Button>
                        </FooterSignUpForm>
                      </AnimateFadeIn>
                      {newsletterError && (
                        <ErrorMessage>{newsletterError}</ErrorMessage>
                      )}

                      <FooterSignupThankyou show={newsletterSubmitted}>
                        <TextBodyLarge>
                          Thanks for signing up, please check your email for a
                          confirmation message.
                        </TextBodyLarge>
                      </FooterSignupThankyou>
                    </FooterNewsletter>
                  </GridItem>

                  <GridItem
                    tinyStart={3}
                    tiny={11}
                    tabletP={13}
                    tabletPStart={2}
                    tabletPOrder={6}
                    desk={4}
                    deskStart={12}
                    deskOrder={6}
                  >
                    <AnimateFadeIn delay={1}>
                      <FooterToTop>
                        <Button
                          aria-label={`To top of page`}
                          as={`button`}
                          variant={buttonVariants.plain}
                          iconOnly={<IconArrowHeadRight />}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(0);
                          }}
                        ></Button>
                      </FooterToTop>
                      <FooterCopyright>
                        <TextBodySmall>
                          © Hume AI Inc. {new Date().getFullYear()}.
                        </TextBodySmall>
                        <TextBodySmall>All rights reserved</TextBodySmall>
                      </FooterCopyright>
                    </AnimateFadeIn>
                  </GridItem>
                </Grid>
              </FooterMain>
            </GridItem>
          </Grid>
        </Container>
      </FooterWrapper>
    </ScrollSection>
  );
};

export default Footer;
