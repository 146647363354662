import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';
import { ButtonIconWrapper } from '../Button/index.style';
import { TextStyle } from 'styles/vars/textStyles.style';

export const FooterWrapper = styled.footer`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.humeTan400};
  border-top: 0.1rem solid ${colors.humeBlack700};
  overflow: hidden;
`;

export const FooterMain = styled.div`
  border-left: 0.1rem solid ${colors.humeBlack700};
`;

export const FooterStart = styled.div`
  height: 100%;
  ${clamp('padding-top', 26, 51)}
`;

export const FooterMark = styled.div`
  ${clamp('width', 28, 42)}
`;

export const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  ${clamp('padding-top', 26, 51)}
  ${clamp('padding-bottom', 26, 51)}
  padding-left: 3rem;
  list-style: none;

  ${mq.tabletL} {
    padding-left: 0;
  }
`;

export const FooterMenuItem = styled.li`
  & + & {
    margin-top: 1.2rem;
  }

  a,
  button {
    display: block;
    font-size: 1.6rem;
  }

  button {
    span {
      border-bottom: 0;
      font-weight: 400;
    }
  }
`;

export const FooterContact = styled.div`
  ${clamp('padding-top', 26, 51)}
  ${clamp('padding-bottom', 26, 51)}
  /* padding-left: 3rem; */

  ${mq.tabletL} {
    padding-left: 0;
  }

  ${FooterMenu} {
    padding: 0;
  }
`;

export const FooterExternalLink = styled.a`
  border-bottom: none;
  text-decoration: none;

  button {
    font-size: 1.6rem;

    > span {
      border-bottom: none;
    }
  }

  span {
    font-weight: 400;
  }
`;

export const FooterSignUpForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1.1rem;
  position: relative;

  &:before {
    background: ${colors.humeBlack700};
    bottom: 0rem;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: 0;
  }

  button {
    bottom: 1.1rem;
    position: absolute;
    right: 0;
  }
`;

export const FooterNewsletter = styled.div`
  ${clamp('padding-top', 26, 51)}
  ${clamp('padding-bottom', 26, 51)}
  /* padding-left: 3rem; */
  position: relative;

  ${FooterSignUpForm} {
    margin-top: 2.3rem;
  }

  &:before {
    background: ${colors.humeBlack700};
    top: 0;
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: -2.2rem;

    ${mq.tiny} {
      right: -16.5vw;
      left: -9.36vw;
    }
  }

  ${mq.tabletP} {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  ${mq.desk} {
    display: none;
  }
`;

export const FooterNewsletterLarge = styled.div``;

export const FooterCopyright = styled.div`
  ${clamp('padding-top', 26, 51)}
  ${clamp('padding-bottom', 26, 51)}
  /* padding-left: 3rem; */

  ${mq.tabletL} {
    padding-left: 0;
  }

  ${mq.desk} {
    padding-right: 12%;
    text-align: right;
    p {
      display: inline-block;

      :first-of-type {
        margin-right: 0.3rem;
      }
    }
  }

  p {
    font-size: 1.6rem;
  }
`;

export const FooterContactOpen = styled.button`
  display: block;
  background: transparent;
  border: none;
`;

export const FooterSignUp = styled.div`
  display: none;
  position: relative;

  &:before {
    background: ${colors.humeBlack700};
    bottom: 0;
    content: '';
    height: 0.1rem;
    left: -2.2rem;
    position: absolute;
    right: -2.2rem;
  }

  ${mq.desk} {
    display: block;
    padding-bottom: 7.2rem;
    padding-top: 6.3rem;
  }
`;

export const FooterSignUpInput = styled.input`
  background: transparent;
  border: none;
  height: 3.3rem;
  width: 100%;

  ${mq.tiny} {
    padding-right: 9.33vw;
  }

  ${mq.tabletP} {
    padding-right: 3.42vw;
  }

  ${mq.desk} {
    padding-right: 1.82vw;
  }

  ${mq.contained} {
    padding-right: 35px;
  }
`;

export const FooterSignUpInputLabel = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
`;
export const FooterSignUpText = styled.div``;

export const FooterToTop = styled.div`
  display: none;
  ${clamp('padding-top', 26, 51)}
  padding-right: 12%;
  text-align: right;

  ${mq.desk} {
    display: block;
  }

  button {
    border-radius: 50%;
    border: 0.1rem solid ${colors.humeBlack700};
    height: 5.6rem;
    min-width: 5.6rem;
    padding: 0;
    transform: rotate(-90deg);
    width: 5.6rem;

    span {
      font-size: 0;
      margin-left: 0;
      padding: 0;
    }
  }

  ${ButtonIconWrapper} {
    margin-top: 0;
  }
`;

export const FooterSignupThankyou = styled.div`
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.5rem;
  display: grid;
  place-items: center;
  align-content: center;
  background-color: ${colors.light};
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? undefined : 'none')};
  transition: opacity 0.2s ${easings.text.in};
  margin-right: -1rem;
  margin-left: -1rem;

  ${mq.tabletL} {
    left: 0.1rem;
  }
`;

export const ErrorMessage = styled.span`
  ${TextStyle.TextBodySmall}
  color: ${colors.roses};
`;
