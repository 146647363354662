import React from 'react';

const Logomark = ({
  width = 42,
  height = 42,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 42"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path
        d="M3.023 21.576C.91 22.063-.402 24.06.11 26.2c.486 2.141 2.54 3.397 4.624 2.912 2.112-.485 3.397-2.483 2.912-4.624-.486-2.14-2.54-3.397-4.624-2.912zM4.85 13.502c1.685 1.342 4.054 1.114 5.424-.599s1.056-4.082-.6-5.423c-1.655-1.342-4.053-1.113-5.423.6-1.37 1.712-1.056 4.08.6 5.422zM15.07 33.793c-1.97-.941-4.224-.142-5.166 1.799-.942 1.94-.171 4.195 1.798 5.166 1.97.942 4.224.142 5.166-1.798.914-1.97.172-4.225-1.798-5.167zM26.94 33.794c-1.97.942-2.74 3.226-1.798 5.166.942 1.941 3.168 2.769 5.166 1.798 1.97-.941 2.74-3.225 1.798-5.166-.941-1.94-3.196-2.768-5.166-1.798zM38.984 21.576c-2.112-.485-4.139.771-4.624 2.912-.485 2.14.8 4.167 2.912 4.624 2.112.485 4.138-.77 4.623-2.912.486-2.14-.799-4.138-2.91-4.623zM37.703 13.502c1.684-1.341 1.97-3.71.6-5.423-1.37-1.712-3.74-1.94-5.424-.6-1.684 1.342-1.97 3.711-.6 5.424 1.37 1.713 3.74 1.94 5.424.6zM21.003 0c-2.226 0-3.882 1.713-3.882 3.882s1.656 3.882 3.882 3.882c2.198 0 3.882-1.713 3.882-3.882C24.885 1.712 23.2 0 21.003 0z"
        fill={fill}
      />
    </svg>
  );
};

export default Logomark;
