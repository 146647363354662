/**
 * this file is copied from https://github.com/benjaminhoffman/gatsby-plugin-mailchimp
 * as this repo is no longer maintained, and current prs are not being reviewed or merged,
 * I figured it would be better to copy it and make edits that I need.
 * */

import jsonp from 'jsonp';
import { validate } from 'email-validator';

/**
 * Make a jsonp request to user's mailchimp list
 *  `param` object avoids CORS issues
 *  timeout to 3.5s so user isn't waiting forever
 *  usually occurs w/ privacy plugins enabled
 *  3.5s is a bit longer than the time it would take on a Slow 3G connection
 *
 * @param {String} url - concatenated string of user's gatsby-config.js
 *  options, along with any MC list fields as query params.
 *
 * @return {Promise} - a promise that resolves a data object
 *  or rejects an error object
 */

const subscribeEmailToMailchimp = ({ url, timeout }) =>
  new Promise((resolve, reject) =>
    jsonp(url, { param: 'c', timeout }, (err, data) => {
      if (err) reject(err);
      if (data) resolve(data);
    }),
  );

/**
 * Build a query string of MC list fields
 *
 * @param {Object} fields - a list of mailchimp audience field labels
 *  and their values. We uppercase because that's what MC requires.
 *  NOTE: GROUPS stay as lowercase (ex: MC uses group field names as `group[21269]`)
 *
 * @return {String} - `&FIELD1=value1&FIELD2=value2&group[21265][2]=group1`
 */
const convertListFields = (fields) => {
  let queryParams = '';
  for (const field in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, field)) {
      // If this is a list group, not user field then keep lowercase, as per MC reqs
      // https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/README.md#groups
      const fieldTransformed =
        field.substring(0, 6) === 'group[' ? field : field.toUpperCase();
      queryParams = queryParams.concat(`&${fieldTransformed}=${fields[field]}`);
    }
  }
  return queryParams;
};

/**
 * Subscribe an email address to a Mailchimp email list.
 * We use ES5 function syntax (instead of arrow) because we need `arguments.length`
 *
 * @param {String} endpoint- required; if you want to override the default MC mailing list
 * @param {String} email - required; the email address you want to subscribe
 *  that's listed in your gatsby-config, pass the list in here
 * @param {String} tags - optional; the tags you want associated with the contact
 * @param {Object} fields - optional; add'l info (columns) you want included w/ this subscriber
 *
 * @return {Object} -
 *  {
 *    result: <String>(`success` || `error`)
 *    msg: <String>(`Thank you for subscribing!` || `The email you entered is not valid.`),
 *  }
 */
const addToMailchimp = function addToMailchimp(endpoint, email, tags, fields) {
  const isEmailValid = validate(email);
  const emailEncoded = encodeURIComponent(email);
  if (!isEmailValid) {
    return Promise.resolve({
      result: 'error',
      msg: 'The email you entered is not valid',
    });
  }

  const timeout = 3500; // eslint-disable-line no-undef

  // Generates MC endpoint for our jsonp request. We have to
  // change `/post` to `/post-json` otherwise, MC returns an error
  endpoint = endpoint.replace(/\/post/g, '/post-json');
  const queryParams = `&EMAIL=${emailEncoded}${convertListFields(
    fields,
  )}&tags=${tags}`;
  const url = `${endpoint}${queryParams}`;

  return subscribeEmailToMailchimp({ url, timeout });
};

export default addToMailchimp;
